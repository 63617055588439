


















import { successNotification } from "@/includes/NotificationService";

import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";

import { Component } from "vue-property-decorator";
import Vue from "vue";

Component.registerHooks([
  'beforeRouteEnter'
])

@Component({
  components: {
    CenteredColumnLayout
  }
})
export default class MigrateChatToModulesLanding extends Vue {
  migrateToModules() {
    this.$store.dispatch('migrateChatToModules', this)
      .then((res) => {
        if (res) {
          this.$baseTemplate.sidebar.reload()

          successNotification(this.$t('modules_migrate_success_message').toString())

          this.$router.replace({ name: 'Community_dashboard' })
        }
      })
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
